import React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';

const variations = cva('flex w-[fit-content] items-center rounded-3xl font-bold', {
  variants: {
    variant: {
      promotion: 'bg-notice text-notice',
      club: 'bg-visual text-brand',
    },
    size: {
      small: 'h-3 px-1 py-0.5 text-xs',
      large: 'h-4 px-1.5 py-1 text-base',
    },
  },
  defaultVariants: {
    size: 'large',
    variant: 'promotion',
  },
});

interface PromotionLabelProps
  extends React.HTMLAttributes<HTMLElement>,
    CustomVariantProps<typeof variations> {
  children?: React.ReactNode;
}

/**
 *
 * @example
 * ```tsx
 * <PromotionLabel isClubPromotion=true/false >
 *  Hello World!
 * </PromotionLabel>
 * ```
 *
 */
export const PromotionLabel: React.FC<PromotionLabelProps> = ({
  children,
  variant,
  size,
  className,
  ...rest
}) => {
  return (
    <div className={cn(variations({ variant, size }), className)} {...rest}>
      {children}
    </div>
  );
};
